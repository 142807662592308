import { Stack } from '@mui/material';
import { useAppState } from '@src/overmind';
import type { ViewProps, ViewType } from '@src/types';
import { motion, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { Container, TopBar } from './components';

export type Layout = 'list' | 'grid';

export { TopBar } from './components';
export { TemplatesView } from './views';

interface DocumentViewsProps extends ViewProps {}

const MIN_WIDTH = 530; // Use to show the login panel // 1 column grid
const MIN_HEIGHT = 333;

export const DocumentViews = ({ title, value }: DocumentViewsProps) => {
  const { userState } = useAppState().auth;
  const { currentLocale } = useAppState().ui;

  const animationControl = useAnimation();
  const { width: _windowWidth } = useWindowSize();

  const [width, setWidth] = useState(MIN_WIDTH);
  const [height, setHeight] = useState(MIN_HEIGHT);
  const [layout, setLayout] = useState<Layout>('list');
  const [type, setType] = useState<ViewType>('samples');

  useEffect(() => {
    changeViewSize();
  }, []);

  useEffect(() => {
    changeViewSize();
  }, [userState, _windowWidth]);

  useEffect(() => {
    switchView();
  }, [currentLocale, value]);

  const changeViewSize = () => {
    // Check additonally if we have enough space to make the unauthenticated view large enough for the longer example files we now have. This should probably be more responsive in general, but upstream also works with px-units.
    setWidth(userState === 'AUTHENTICATED' ? getMaxWidth() : ((_windowWidth /2) > MIN_WIDTH ? MIN_WIDTH: 290)); 
    setHeight(userState === 'AUTHENTICATED' ? getMaxHeight() : MIN_HEIGHT);
  };

  const getMaxHeight = () => {
    //*  Screen width < 1536px: 444px
    //*  Screen width > 1536px: 455px

    return _windowWidth < 1536 ? 444 : 555;
  };

  const getMaxWidth = () => {
    //*  Screen width < 800px: 1 column grid
    //*  Screen width < 1100px: 2 column grid
    //*  Screen width < 1536px: 3 column grid
    //*  Screen width > 1536px: 4 column grid

    return _windowWidth < 800 ? 280 : _windowWidth < 1100 ? 535 : _windowWidth < 1536 ? 810 : 1062;
  };

  const switchView = async () => {
    /*
    Comment out the hide animation for the views showing examples, recent documentes etc. on the landing page. This hide animation
    makes a smooth blending in of the list after it got loaded, adding a nice visual effect. However, it also can make it stuck on some occasion,
    endlessly showing the loading animation and never displaying the list itself. By commenting out the hiding animation, it at least seems, that 
    the list will always show up which is good, since presenting the user an endless loading animation on the landing page instead of example
    documents is bad. Why the loading animation gets stuck should probably be further debugged, but for the time beeing just getting rid of it fixes 
    an annoying bug without sacrificing to much.
    */
    //await animationControl.start('hide');
    setType(value);
    await animationControl.start('show');
  };

  const changeLayout = (value: Layout) => setLayout(value);

  return (
    <Stack
      width={width}
      pt={2}
      overflow="auto"
      component={motion.div}
      animate={{ width, height }}
      transition={{ type: 'tween', duration: userState == 'UNAUTHENTICATED' ? 2 : 0.5 }}
    >
      <TopBar
        animationControl={animationControl}
        layout={layout}
        onLayoutChange={changeLayout}
        title={title}
      />
      <Container
        animationControl={animationControl}
        height={height}
        layout={layout}
        width={width}
        type={type}
      />
    </Stack>
  );
};
