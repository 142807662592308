import { SupportedProviderIds } from '@src/config';
import type { ProviderId, ProviderService } from '../../services/types';

export const loadModule = async (providerId: ProviderId): Promise<ProviderService | null> => {
  if (!SupportedProviderIds.some((p) => providerId.startsWith(p))) return null;

  if (providerId === 'github') return (await import('../../services/github')).provider;
  // Separate and special loading routine for the gitlab-sefhosted provider. This one needs to be a class, becaue we need to be able to initialize multiple instances of it.
  if (providerId.startsWith('gitlab_selfhosted')) {
    module = (await import('../../services/gitlab_selfhosted'))
    const Provider = module.default;
    const provider = new Provider();
    return provider
  };
  if (providerId === 'gitlab') return (await import('../../services/gitlab')).provider;
  if (providerId === 'orcid') return (await import('../../services/orcid')).provider;

  return null;
};
