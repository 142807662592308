import type { User } from '@src/types';
import { TimerServiceKeycloak, type TimerServiceProps } from './timerService';

interface State {
  user?: User;
  userState: 'UNAUTHENTICATED' | 'AUTHENTICATING' | 'AUTHENTICATED';
  timerServiceKeycloak: TimerServiceProps
}

export const state: State = {
  userState: 'AUTHENTICATING',
  timerServiceKeycloak: TimerServiceKeycloak
};
