import { Link, Typography, useMediaQuery, useTheme, Container, Box } from '@mui/material';
import { useAppState } from '@src/overmind';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

export const About = () => {
  const { breakpoints } = useTheme();

  const { currentLocale } = useAppState().ui;

  const [content, setContent] = useState('');

  const [contentChangelog, setContentChangelog] = useState('');

  useEffect(() => {
    loadContent();
  }, []);

  useEffect(() => {
    loadContent();
  }, [currentLocale]);

  useEffect(() => {
    loadContent_Changelog_Gitlab();
  }, []);
  
  useEffect(() => {
    loadContent_Changelog_Gitlab();
  }, [currentLocale]);
  

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const loadContent = async () => {
    const response = await fetch(`./content/about/${currentLocale}.md`);
    const text = await response.text();
    setContent(text);
  };

  const loadContent_Changelog_Gitlab = async () => {
    // Defaults to english, will always dynamically load from our Gitlab wiki. Also defaults to dev, unless url contains 'stable', then stable changelog will be loaded
    const lang_extension = (currentLocale == "de") ? 'deutsch' : 'english';
    const branch_type = window.location.host.includes("stable") ? "main" : "dev"
    const file = `Changelog-${branch_type}-${lang_extension}`
    const response = await fetch(`https://code.fbi.h-da.de/api/v4/projects/30163/wikis/${file}`, { mode: "cors", credentials:"same-origin"});
    const text = await response.json();
    // console.log(text.content)
    setContentChangelog(text.content);
  };

  return (
    <>
      <Box py={3}>
        <div style={{overflowY:"auto",maxHeight:"20vh"}}>
          <ReactMarkdown
            components={{
              h1: ({ node, ...props }) => (
                <Typography
                  component="h4"
                  mb={2}
                  sx={{ fontWeight: 100 }}
                  variant={isMobile ? 'h5' : 'h4'}
                  {...props}
                />
              ),
              h2: ({ node, ...props }) => (
                <Typography component="h5" mb={1} variant={isMobile ? 'h6' : 'h5'} {...props} />
              ),
              p: ({ node, ...props }) => <Typography my={1} {...props} />,
              a: ({ node, ...props }) => (
                <Link underline="hover" target="_blank" rel="noopener noreferrer" {...props} />
              ),
            }}
          >
            {contentChangelog}
          </ReactMarkdown>
        </div>
      </Box>

      <ReactMarkdown
      components={{
        h1: ({ node, children }) => (
          <Typography
            component="h1"
            mb={4}
            sx={{ fontWeight: 300 }}
            variant={isMobile ? 'h3' : 'h2'}
            {...node?.properties}
          >
            {children}
          </Typography>
        ),
        h2: ({ node, children }) => (
          <Typography component="h2" mb={3} variant={isMobile ? 'h4' : 'h3'} {...node?.properties}>
            {children}
          </Typography>
        ),
        h3: ({ node, children }) => (
          <Typography
            component="h3"
            mb={2.5}
            variant={isMobile ? 'h5' : 'h4'}
            {...node?.properties}
          >
            {children}
          </Typography>
        ),
        h4: ({ node, children }) => (
          <Typography component="h4" mb={2} variant={isMobile ? 'h6' : 'h5'} {...node?.properties}>
            {children}
          </Typography>
        ),
        h5: ({ node, children }) => (
          <Typography mb={1.5} variant={isMobile ? 'subtitle1' : 'h6'} {...node?.properties}>
            {children}
          </Typography>
        ),
        h6: ({ node, children }) => (
          <Typography
            component="h6"
            mb={1}
            variant={isMobile ? 'subtitle2' : 'subtitle1'}
            sx={{ fontWeight: 700 }}
            {...node?.properties}
          >
            {children}
          </Typography>
        ),
        p: ({ node, children }) => (
          <Typography my={1} {...node?.properties}>
            {children}
          </Typography>
        ),
        a: ({ node, href, children }) => (
          <Link
            aria-label={`${children?.toString()} (opens in new window)`}
            href={href}
            underline="hover"
            target="_blank"
            rel="noopener noreferrer"
            {...node?.properties}
          >
            {children}
          </Link>
        ),
      }}
    >
      {content}
    </ReactMarkdown>
    </>
  );
};
